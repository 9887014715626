import React from "react";
import "./Header.css";

import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { Offcanvas } from "react-bootstrap";
import { Container } from "react-bootstrap";

function Header() {
  return (
    <Navbar bg="dark" variant="dark" expand={false}>
      <Container fluid>
        <Navbar.Brand href="#">Johnny X NFT</Navbar.Brand>
        <Navbar.Toggle aria-controls="offcanvasNavbar" />
        <Navbar.Offcanvas
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id="offcanvasNavbarLabel">Follow Us</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 pe-3">
              <Nav.Link href="https://www.instagram.com/johnnyxnft/?utm_medium=copy_link"
                target="_blank"
                rel="noopener noreferrer">
                Instragram
              </Nav.Link>
              <Nav.Link href="https://twitter.com/johnnyx_nft"
                target="_blank"
                rel="noopener noreferrer">
                Twitter</Nav.Link>
              <Nav.Link href="https://discord.gg/johnnyxnft"
                target="_blank"
                rel="noopener noreferrer">
                Discord</Nav.Link>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  )
}

export default Header;