import { ethers } from "ethers";
import { useState } from 'react';
import React from "react"
import contract from './contracts/JohnnyXGenesis.json';
import { useInterval } from "./Callback";

const ABI = contract.abi;
const CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS
const PROVIDER = process.env.REACT_APP_PROVIDER_URL
const ERROR = "Install web3 provider to see minting status, i.e. MetaMask, etc.";

function MintStatus() {
  const [numberMinted, setNumberMinted] = useState(0);
  const [error, setError] = useState(null);

  useInterval(() => {
    mintStatus();
  }, 1000);

  const mintStatus = async () => {
    try {
      const provider = ethers.getDefaultProvider(PROVIDER);
      const johnnyx = new ethers.Contract(CONTRACT_ADDRESS, ABI, provider);
      let numberMinted = await johnnyx.totalSupply();
      setNumberMinted(numberMinted.toNumber());
    } catch (err) {
      console.log(err);
      setError(ERROR);
    }
  };

  return (
    <div>
      <h2>
        Minted: {numberMinted} : 500
      </h2>
      <p className="danger">{error}</p>
    </div>
  );
}

export default MintStatus;
