import React from 'react';
import { useEffect, useState } from 'react';

import { Button } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import Mint from '../mint/Mint';

function Wallet() {
  const [showWalletNotConnected, setShowWalletNotConnected] = useState(false);
  const [currentAccount, setCurrentAccount] = useState(null);

  const connectWalletButton = () => {
    return (
      <Button onClick={connectWalletHandler} className='cta-button connect-wallet-button'>
        Connect Wallet
      </Button>
    )
  }

  const checkWalletIsConnected = async () => {
    const { ethereum } = window;

    if (!ethereum) {
      console.log("Wallet is not currently connected.");
      return;
    }

    const accounts = await ethereum.request({ method: 'eth_accounts' });

    if (accounts.length === 0) {
      console.log("No account found in wallet.");
    }

    const account = accounts[0];
    console.log("Found an authorized account: ", account);
    setCurrentAccount(account);
  }

  const connectWalletHandler = async () => {
    const { ethereum } = window;

    if (!ethereum) {
      setShowWalletNotConnected(true);
      console.log("No metamask wallet found.");
      return;
    }

    try {
      const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
      console.log("Found an account! Address: ", accounts[0]);
      setCurrentAccount(accounts[0]);
    } catch (err) {
      setShowWalletNotConnected(true);
      console.log(err)
    }
  }

  useEffect(() => {
    checkWalletIsConnected();
  }, [])

  return (
    <div>
      <WalletNotInstalledModal
        show={showWalletNotConnected}
        onHide={() => setShowWalletNotConnected(false)}
      />
      {currentAccount ? <Mint /> : connectWalletButton()}
    </div>
  )
}

function WalletNotInstalledModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Wallet Not Found
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>Please Install a Web3 Provider.</h5>
        <ul>
          <li>Brave Browser</li>
          <li>MetaMask</li>
          <li>ConnectWallet</li>
          <li>etc..</li>
        </ul>
        <p>
          For example:
        </p>
        <a href="https://metamask.io/">https://metamask.io/</a>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default Wallet;