import React from "react";
import "./Footer.css";

import { Container, Row } from "react-bootstrap";

function Footer() {
  return (
    <footer>
      <Container>
        <Row>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer;