
import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';

import Header from './elements/Header';
import Footer from './elements/Footer';
import Main from './elements/Main';

function App() {
  return (
    <div id="mainContent">
      <Header />
      <Main />
      <Footer />
    </div>
  )
}

export default App;