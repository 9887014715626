import React from "react";
import { Container, Row } from "react-bootstrap";
import MintStatus from "./mint/MintStatus";
import Countdown from 'react-countdown';

import Wallet from './wallet/Wallet';

function Main() {
  const verfifiedSmartContract = process.env.REACT_APP_ETH_SCAN_ADDRESS;

  const Completionist = () => <span>Let the minting commence!</span>;

  return (
    <Container className="container-fluid text-light p-5">
      <Container className="container p-2">
        <h1 className="display-4 fw-bold">Johnny X NFT Genesis Collection</h1>
        <hr />
        <p>Introducing the Johnny X NFT Genesis Collection.
          This is a collection of 500 individual characters
          based on RTFKT Clone X #18283 owned by yours truly, John Trottier.
          Each one of these 500 clones represents a VIP pass for all
          Johnnyskicks' future product launches. From exclusive drops,
          limited collaborations, events and much more.
        </p>
      </Container>
      <Container className="container p-5 bg-dark">
        <Row className="row alert alert-success">
          <div className="col-md-9">
            <p>Current contract is live on mainnet.</p>
            <a href={verfifiedSmartContract} target="_blank" rel="noopener noreferrer">
              Verified Smart Contract
            </a>
          </div>
          <div className="col-md-3">
            Countdown: <Countdown date={1646503200000}>
              <Completionist />
            </Countdown>
          </div>
        </Row>
        <Row className="row">
          <div className="col-md-9">
            <h1 className="cta-title">Mint your own JohnnyXGenesis!</h1>
            <MintStatus />
            <a href="https://opensea.io/collection/johnnyx-nft-collection" target="_blank" rel="noopener noreferrer">
              OpenSea Link
            </a>
          </div>
          <div className="col-md-3">
            <Wallet />
          </div>
        </Row>
      </Container>
      <Container className="container p-2">
        <h1>Physical Merch & Live Events</h1>
        <hr />
        <p>This Genesis collection is in direct alignment with Johnnyskicks.
          We will expand into apparel, toys, and signature sneakers.
          Holders of the Johnny X Genesis NFT will represent your VIP
          pass to special drops, collaborations, live events and more.
          As a group of crypto enthusiasts, innovators and artists with a
          unique view, we will decide as a community the direction of
          Johnny X. I do not want to over promise and under deliver.
          I have grand ambitions for this project and how we can grow it together.
        </p>
      </Container>
      <Container className="container p-2">
        <h1>Secondary & Future Collections</h1>
        <hr />
        <p>Holders of the JohnnyXNFT Genesis Collection will automatically be
          waitlisted for every NFT project I release going forward.
          Genesis holders will be holding the rarest, most beneficial
          token out of any project I will ever release. I want to give
          back the most benefits to the 500 people that truly believed in me
          and my vision from the very beginning! This entire project will
          be based on community decisions.
        </p>
      </Container>
      <Container className="container p-2">
        <h1>About me (the artist)</h1>
        <hr />
        <p>My name is John Trottier and I am an American graphic designer,
          NFT artist, sneaker creator, photographer and CEO of Johnnyskicks.
          I am here to change the world of design and bespoke products
          in the real world and digital. I have been an avid art and
          sneaker collector  and art enthusiast for twenty years.
          I have been able to turn my life's passion into a reality
          by bringing my designs to life in the form of limited
          edition product. I have had the ability to work alongside
          some of the biggest artist, creators and brands in the world;
          Kevin Hart, Lil Baby, Rich the Kid, J Balvin to name a few.
          I'm excited to push my designs and creativity in the new
          digital realm; if the form of Johnny X NFT!
        </p>
      </Container>
    </Container >
  )
}


export default Main;