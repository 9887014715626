import { ethers } from 'ethers';
import { useState } from 'react';
import { Button, Form, Modal, ProgressBar } from 'react-bootstrap';
import contract from './contracts/JohnnyXGenesis.json';
import { useInterval } from "./Callback";

const ABI = contract.abi;
const ETH_NET_ADDRESS = process.env.REACT_APP_ETH_NET_ADDRESS;
const CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS
const ETH_SCAN_ADDRESS = process.env.REACT_APP_ETH_SCAN_ADDRESS

function Mint() {
  const maximumTokensPerAccount = 2;
  const costPerToken = 0.15;

  const [showMintingStatus, setShowMintingStatus] = useState(false);
  const [statusText, setStatusText] = useState("Pending");
  const [statusPercentage, setStatusPercentage] = useState(0);
  const [transactionStatus, setTransactionStatus] = useState("Pending");
  const [transactionHashLink, setTransactionHashLink] = useState("Pending");
  const [numberToMint, setNumberToMint] = useState(1);
  const [buttonStatus, setButtonStatus] = useState("cta-button mint-nft-button disabled");

  useInterval(() => {
    initButton();
  }, 1000);

  const initButton = async () => {
    try {
      const { ethereum } = window;

      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const nftContract = new ethers.Contract(CONTRACT_ADDRESS, ABI, signer);

        if ((await nftContract.isActive())) {
          setButtonStatus("cta-button mint-nft-button")
        }
      } else {
        console.log("Ethereum object does not exist");
      }
    } catch (err) {
      console.log(JSON.stringify(err));
      setTransactionStatus(err.error.message);
    }
  }

  const mintNftHandler = async () => {
    try {
      const { ethereum } = window;

      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const nftContract = new ethers.Contract(CONTRACT_ADDRESS, ABI, signer);

        console.log("Initialize payment");
        setStatusText("Initializing payment please wait...");
        setStatusPercentage(25);
        setShowMintingStatus(true);
        setTransactionStatus("Pending");
        setTransactionHashLink(null);

        if ((await nftContract.totalSupply()).toNumber() === 500) {
          setStatusText("Apologies all of the Johnny X Genesis NFTs have sold.");
          setButtonStatus("cta-button mint-nft-button disabled")
          return;
        }

        const ether = numberToMint * costPerToken;
        console.log("Attempting to purchase " + numberToMint + " tokens, with cost: " + ether.toString());
        let nftTxn = await nftContract.mintNFTs(
          numberToMint,
          { value: ethers.utils.parseEther(ether.toString()) }
        );

        setStatusText("Minting pleae wait...");
        setStatusPercentage(50);
        console.log("Minting... please wait");
        await nftTxn.wait();

        setTransactionStatus("Completed Transaction.");
        setTransactionHashLink(ETH_NET_ADDRESS + "tx/" + nftTxn.hash);
        setStatusText(`Minted, see transaction: ${nftTxn.hash}.`);
        setStatusPercentage(100);
        console.log(`Minted, see transaction: ${ETH_NET_ADDRESS}/tx/${nftTxn.hash}`);

      } else {
        console.log("Ethereum object does not exist");
      }

    } catch (err) {
      console.log(JSON.stringify(err));
      setTransactionStatus(err.error.message);
    }
  }

  function MintingNftStatus(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Minting NFT
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6>Contract:</h6>
          <a href={ETH_SCAN_ADDRESS} target="_blank" rel="noopener noreferrer">
            {CONTRACT_ADDRESS}
          </a>
          <hr />
          <h6>{statusText}</h6>
          <p>Transaction status: </p>
          <a href={transactionHashLink} target="_blank" rel="noopener noreferrer">
            {transactionStatus}
          </a>
          <ProgressBar animated now={statusPercentage} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  const updateNumberToMint = e => {
    setNumberToMint(e.target.value);

    if (e.target.value > maximumTokensPerAccount || e.target.value <= 0) {
      setButtonStatus("cta-button mint-nft-button disabled");
      return;
    }

    setButtonStatus("cta-button mint-nft-button");
  }

  return (
    <div>
      <MintingNftStatus
        show={showMintingStatus}
        onHide={() => setShowMintingStatus(false)}
      />

      <Form>
        <Form.Group className="mb-3" controlId="formNumberToMint">
          <Form.Label>Number to Mint (limit {maximumTokensPerAccount}):</Form.Label>
          <Form.Control
            type="number"
            placeholder="Min: 1 Max: {maximumTokensPerAccount}"
            value={numberToMint}
            onChange={updateNumberToMint}
          />
        </Form.Group>
        <Button onClick={mintNftHandler}
          id="mintNftButton"
          className={buttonStatus}>
          Mint JohnnyXGenesis NFT
        </Button>
      </Form>
    </div>
  )
}

export default Mint;